import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "scheduledOffers/";

class ScheduledOffersService {
  getScheduledOffers() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  delete(offerId) {
    return axios.delete(API_URL + offerId, { headers: authHeader() });
  }
}

export default new ScheduledOffersService();
