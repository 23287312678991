import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import FastflexService from "../services/fastflex.service";
import { RefreshCw } from "react-feather";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default class Logs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      currentUser: { username: "" },
      logs: "",
      acceptedOffers: "",
      lastOffers: "",
      counter: 10,
      isOpen: false,
      isOpen2: false,
      status: "LOADING",
      lastOffersCount: "~",
      systemPaused: false,
      isLoading: true
    };

    this.intervalID = 0;
  }

  loadLogs() {
    FastflexService.getLogs()
      .then((response) => {
        const lastLine = response.data.split("[").slice(-1)[0];
        const systemPaused = lastLine.includes("System paused");
        this.setState({
          logs: response.data,
          counter: 10,
          systemPaused: systemPaused,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ counter: 10 });
      });
  }

  loadLastOffers() {
    FastflexService.getLastOffers()
      .then((response) => {
        const lastOffersCount = response.data.split("[").length - 1;
        this.setState({
          lastOffers: response.data,
          lastOffersCount: lastOffersCount,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadAcceptLogs() {
    FastflexService.getAcceptLogs()
      .then((response) => {
        this.setState({ acceptedOffers: response.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadAllLogs() {
    this.loadLogs();

    if (this.state.status !== "RUNNING" || this.state.systemPaused) return;

    this.loadLastOffers();
  }

  clearLogs() {
    if (this.state.status !== "STOPPED") return;

    FastflexService.clearLogs().then(() => {
      this.loadAllLogs();
      this.setState({ isOpen: false });
    });
  }

  loadStatus() {
    FastflexService.getStatus().then((statusResponse) => {
      this.setState({
        status: statusResponse.data,
      });
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500)

    this.loadStatus();
    this.loadAcceptLogs();
    this.loadAllLogs();

    this.intervalID = setInterval(() => {
      if (this.state.counter > 0 && this.logs !== "") {
        this.setState({ counter: this.state.counter - 1 });
      } else if (this.state.counter === 0) {
        this.loadAllLogs();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            {this.state.isLoading && <Loader />}
            <Tabs
              defaultActiveKey="offers"
              transition={false}
              id="justify-tab-example"
              className="mb-3 mt-2"
              justify
            >
              <Tab eventKey="offers" title="LOGS / OFFERS">
                <div>
                  <div className="d-flex justify-content-between" style={{ marginInline: 15 }}>
                    <h5>LOGS</h5>
                    <div>
                      <RefreshCw className="icon-refresh" size={17} /> Refreshing Logs
                      in <strong>{this.state.counter}</strong>s
                    </div>
                  </div>

                  <ul className="list-group mt-1">
                    {this.state.logs
                      .split("[")
                      .slice(1)
                      .reverse()
                      .map((item) => {
                        const parts = item.split("]");
                        var date = parts[0];
                        if (date.includes("Z")) {
                          date = new Date(date).toLocaleString();
                        }
                        if (parts[1].includes("<a>")) {
                          const links = parts[1].split("<a>");
                          return (
                            <li
                              className="list-group-item"
                              style={{ whiteSpace: "pre-line" }}
                            >
                              {"[" + date + "]" + links[0]}
                              <a
                                href={links[1]}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {links[2]}
                              </a>
                              {links[3]}
                            </li>
                          );
                        }
                        return (
                          <li
                            className="d-flex card-filter card-filter-content align-items-center"
                            style={{ marginBottom: 8, whiteSpace: "pre-line" }}
                          >
                            {
                              <div className="d-flex flex-fill row">
                                <label>{parts[1]}</label>
                                <label className="text-end" style={{ marginTop: -21 }}>{date}</label>
                              </div>
                            }
                            {/* `{"[" + date + "]" + parts[1]}` */}
                          </li>
                        );
                      })}
                  </ul>
                  {this.state.lastOffers ? (
                    <h5
                      style={{ marginTop: 8, marginLeft: 15 }}
                    >{`OFFERS (${this.state.lastOffersCount})`}</h5>
                  ) : null}
                  <ul className="list-group mt-1">
                    {this.state.lastOffers
                      .split("[")
                      .slice(1)
                      .map((item) => {
                        const parts = item.split("]");
                        var date = parts[0];
                        if (date.includes("Z")) {
                          date = new Date(date).toLocaleString();
                        }
                        return (
                          <li
                            className="d-flex card-filter card-filter-content align-items-center"
                            style={{ marginBottom: 8, whiteSpace: "pre-line" }}
                          >
                            {
                              <div className="d-flex flex-fill row">
                                <label>{parts[1]}</label>
                                <label className="text-end">{date}</label>
                              </div>
                            }
                            {/* {"[" + date + "]" + parts[1]} */}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </Tab>
              <Tab eventKey="logs-accepted" title="ACCEPTED">
                <ul className="list-group mt-1">
                  {this.state.acceptedOffers
                    .split("[")
                    .slice(1)
                    .reverse()
                    .map((item) => {
                      const parts = item.split("]");
                      var date = parts[0];
                      if (date.includes("Z")) {
                        date = new Date(date).toLocaleString();
                      }
                      return (
                        <li
                          className="d-flex card-filter card-filter-content align-items-center"
                          style={{ marginBottom: 8, whiteSpace: "pre-line" }}
                        >
                          {
                            <div className="d-flex flex-fill row">
                              <label>{parts[1]}</label>
                              <label className="text-end" style={{ marginTop: -21 }}>{date}</label>
                            </div>
                          }
                          {/* {"[" + date + "]" + parts[1]} */}
                        </li>
                      );
                    })}
                </ul>
              </Tab>
            </Tabs>
          </div>
        ) : null}
      </div>
    );
  }
}
