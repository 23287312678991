import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "auth/signin", {
        username,
        password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          this.save(response.data);
        }

        return response.data;
      });
  }

  save(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  showedInitialNotification() {
    var user = this.getCurrentUser();
    user.showedInitialNotification = true;
    this.save(user);
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, phone, email, password, indicatorCode) {
    return axios.post(API_URL + "auth/signup", {
      username,
      phone,
      email,
      password,
      indicatorCode,
    });
  }

  finishRegister(username, amazonUrl) {
    return axios.post(API_URL + "auth/finishRegister", {
      username,
      amazonUrl,
    });
  }

  finishSync(amazonUrl) {
    return axios.post(
      API_URL + "auth/finishSync",
      {
        amazonUrl,
      },
      { headers: authHeader() }
    );
  }

  getSyncInfo() {
    return axios.get(API_URL + "auth/getSyncInfo", { headers: authHeader() });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
