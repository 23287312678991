import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service";
import LogsService from "../../services/logs.service";

export default class Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      currentUser: { username: "" },
      logs: "",
      counter: 30,
    };

    this.intervalID = 0;
  }

  loadLogs() {
    LogsService.getError()
      .then((response) => {
        this.setState({ logs: response.data, counter: 30 });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ counter: 30 });
      });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser });

    this.loadLogs();

    this.intervalID = setInterval(() => {
      if (this.state.counter > 0 && this.logs !== "") {
        this.setState({ counter: this.state.counter - 1 });
      } else if (this.state.counter === 0) {
        this.loadLogs();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            <div className="d-flex justify-content-center">
              <h4 className="text-header">ERROR</h4>
            </div>
            <label>
              Refreshing Error Log in <strong>{this.state.counter}</strong>s
            </label>
            <ul className="list-group mt-1">
              <li
                className="list-group-item"
                style={{ whiteSpace: "pre-line" }}
              >
                {this.state.logs.replace(/\[2023/g, "\n[2023")}
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
