import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  AlertCircle,
  ArrowLeft,
  ArrowRight,
  Calendar,
  Clock,
  CreditCard,
  Filter,
  Gift,
  List,
  Mail,
  MessageCircle,
  MessageSquare,
  Phone,
  PlayCircle,
  Search,
  Send,
  StopCircle,
  User,
  UserX,
  X,
} from "react-feather";
import { Link, Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import FastflexService from "../services/fastflex.service";
import FiltersService from "../services/filters.service";
import UserService from "../services/user.service";
import Form from "react-validation/build/form";
import EarningsService from "../services/earnings.service";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.onChangeAmazonUrl = this.onChangeAmazonUrl.bind(this);
    this.loadPreviousEarnings = this.loadPreviousEarnings.bind(this);
    this.loadNextEarnings = this.loadNextEarnings.bind(this);
    this.days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    this.state = {
      redirect: null,
      currentUser: null,
      username: "",
      status: "LOADING",
      filters: undefined,
      earnings: {},
      currentWeek: 0,
      isOpenM2: false,
      active: false,
      membershipValid: false,
      isStartConfirmationModalOpen: false,
      isLoading: true,
    };
  }

  loadUser() {
    UserService.getSelf().then((response) => {
      this.setState({
        username: response.data.username,
        active: response.data.active,
        membershipValid: response.data.membershipValid,
        needSync: response.data.needSync,
        needsAmazonValidation: response.data.needsAmazonValidation,
      });
      this.updateExpiration(response.data.expirationDate);
    });
  }

  updateExpiration(expirationDate) {
    const date = new Date(
      expirationDate.substring(0, 19).replace(" ", "T") + ".000Z"
    );
    const diffTime = Math.abs(date - new Date());
    const remainingDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = diffTime / (1000 * 60 * 60);
    const remainingHours = Math.floor(diffHours - remainingDays * 24);
    const diffMinutes = diffTime / (1000 * 60);
    const remainingMinutes = Math.floor(
      diffMinutes - remainingDays * 24 * 60 - remainingHours * 60
    );
    const expireIn =
      remainingDays + "D " + remainingHours + "H " + remainingMinutes + "M";

    this.setState({ expireIn: expireIn, expiredAt: date.toLocaleString() });
  }

  loadStatus() {
    FiltersService.getFilters(true).then((filtersResponse) => {
      FastflexService.getStatus().then((statusResponse) => {
        this.setState({
          status: statusResponse.data,
          filters: filtersResponse.data,
        });
      });
    });
  }

  loadEarnings() {
    EarningsService.getEarnings(this.state.currentWeek).then((response) => {
      this.setState({
        earnings: response.data || {},
      });
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/" });
    this.setState({
      currentUser: currentUser,
    });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);

    this.loadUser();
    this.loadStatus();
    this.loadEarnings();
  }

  startScript() {
    FastflexService.start().then((response) => {
      this.setState({
        status: response.data,
        isStopped: false,
        isStartConfirmationModalOpen: false,
      });
    });
  }

  handleClick() {
    if (this.state.status === "LOADING") return;

    if (this.state.status === "STOPPED") {
      this.openModalStartConfimation();
    } else {
      FastflexService.stop().then((response) => {
        this.setState({ status: response.data, isStopped: true });
      });
    }
  }

  loadPreviousEarnings() {
    this.setState(
      {
        currentWeek: this.state.currentWeek - 1,
      },
      () => {
        this.loadEarnings();
      }
    );
  }

  loadNextEarnings() {
    this.setState(
      {
        currentWeek: Math.min(0, this.state.currentWeek + 1),
      },
      () => {
        this.loadEarnings();
      }
    );
  }

  openModalSupport = () => this.setState({ isOpenM2: true });
  closeModalSupport = () => this.setState({ isOpenM2: false });

  openModalStartConfimation = () =>
    this.setState({ isStartConfirmationModalOpen: true });
  closeModalStartConfimation = () =>
    this.setState({ isStartConfirmationModalOpen: false });

  onChangeAmazonUrl(e) {
    this.setState({
      amazonUrl: e.target.value,
    });
  }

  handleValidation(e) {
    e.preventDefault();

    FastflexService.validate(this.state.amazonUrl).then(() => {
      window.location.reload(false);
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    return (
      <div className="container">
        {this.state.currentUser ? (
          <div className="">
            {this.state.isLoading && <Loader />}
            <div
              className="row d-flex justify-content-between"
              style={{ margin: 1 }}
            >
              <Modal
                show={this.state.isStartConfirmationModalOpen}
                onHide={this.closeModalStartConfimation}
                centered
              >
                <Modal.Body>
                  <div className="col d-flex justify-content-between">
                    <h5 className="text-header mt-2">
                      <AlertCircle size={20} /> Start Offer Searching
                    </h5>
                    <button
                      className="text-header"
                      onClick={this.closeModalStartConfimation}
                    >
                      <X size={20} />
                    </button>
                  </div>
                  <hr />
                  <label>
                    Make sure your Amazon Flex app is fully closed, in order to
                    avoid conflict and keep your account safe from banishment.
                  </label>
                  <hr />
                  <div className="mt-3 d-flex justify-content-between ">
                    <button
                      className="btn btn-primary form-control m-2"
                      onClick={() => {
                        this.startScript();
                      }}
                    >
                      <strong>CONTINUE</strong>
                    </button>
                    <button
                      className="btn btn-danger form-control mt-2"
                      onClick={this.closeModalStartConfimation}
                    >
                      <strong>CANCEL</strong>
                    </button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={this.state.isOpenM2}
                onHide={this.closeModalSupport}
                centered
              >
                <Modal.Body>
                  <div className="col d-flex justify-content-between">
                    <h4 className="text-header mt-2">
                      <MessageSquare size={18} /> Support
                    </h4>
                    <button
                      className="text-header"
                      onClick={this.closeModalSupport}
                    >
                      <X size={20} />
                    </button>
                  </div>
                  <hr />
                  <div className="mt-3 text-center">
                    <h6>
                      <Phone size={18} /> TEXT-MSG: +1(781) 369-5262
                    </h6>
                    <h6>
                      <Mail size={18} /> SUPPORT@FASTFLEX.APP
                    </h6>
                  </div>
                  <div className="row mt-4">
                    <a href="whatsapp://send?phone=17813695262">
                      <button className="btn btn-success form-control">
                        <MessageCircle size={20} />
                        <strong> GO TO WHATSAPP</strong>
                      </button>
                    </a>
                    <a href="tg://resolve?domain=fastflex">
                      <button className="btn btn-primary form-control mt-2">
                        <Send size={20} />
                        <strong> GO TO TELEGRAM</strong>
                      </button>
                    </a>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="d-flex card-status align-items-center justify-content-around">
                <h5
                  className="mt-2"
                  style={{ fontWeight: "normal", fontSize: 16 }}
                >
                  {"👋 Welcome, " + this.state.username.toUpperCase() + "!"}
                </h5>
                <Link to={"/profile"} className="user-circle">
                  <User size={25} color="orange" />
                </Link>
              </div>
              <div
                className="d-flex card-status mt-2 align-items-center justify-content-around"
                style={{ height: 45 }}
              >
                <div>
                  {this.state.status === "STOPPED" ? (
                    <StopCircle
                      size={19}
                      color="red"
                      style={{ marginRight: 5, marginBottom: 2 }}
                    />
                  ) : (
                    <PlayCircle
                      size={19}
                      color="green"
                      style={{ marginRight: 5, marginBottom: 2 }}
                    />
                  )}
                  <label>{this.state.status}</label>
                </div>

                <div>
                  {this.state.active === this.state.membershipValid ? (
                    <Clock
                      size={18}
                      color="orange"
                      style={{ marginRight: 5, marginBottom: 2 }}
                    />
                  ) : null}
                  {this.state.active !== this.state.membershipValid ? (
                    <UserX
                      size={18}
                      color="red"
                      style={{ marginRight: 5, marginBottom: 2 }}
                    />
                  ) : null}
                  {this.state.active === this.state.expiredAt ? (
                    <Clock
                      size={18}
                      color="red"
                      style={{ marginRight: 5, marginBottom: 2 }}
                    />
                  ) : null}
                  <label>
                    {this.state.active
                      ? "EXPIRE IN " + this.state.expireIn
                      : this.state.membershipValid
                        ? "USER DEACTIVATED"
                        : "EXPIRED AT " + this.state.expiredAt}
                  </label>
                </div>
              </div>
            </div>
            <div className="card-status mt-2">
              <div className="d-flex justify-content-between align-text-center">
                <h4 className="text-header p-1" style={{ marginLeft: 15 }}>
                  Earnings
                </h4>
                <div style={{ marginTop: 5, marginRight: 25 }}>
                  <label style={{ fontSize: 15 }}>Worked Hours: </label>
                  <strong style={{ fontSize: 17 }}>
                    {" "}
                    {(
                      Object.values(this.state.earnings).reduce(
                        (a, b) => a + b.duration,
                        0
                      ) /
                      1000 /
                      60.0 /
                      60.0
                    ).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </strong>
                </div>
              </div>
              <div
                className="d-flex card-content col text-center justify-content-around"
                style={{ height: 85 }}
              >
                {Object.keys(this.state.earnings).map((key) => {
                  const date = new Date(key);
                  return (
                    <div className="row">
                      <label style={{ fontSize: 15 }}>
                        $
                        {this.state.earnings[key].value.toLocaleString(
                          undefined,
                          { maximumFractionDigits: 2 }
                        )}
                      </label>
                      <label style={{ color: "orange" }}>
                        {this.days[date.getDay()]}
                      </label>
                      <label style={{ fontSize: 14 }}>{date.getDate()}</label>
                    </div>
                  );
                })}
              </div>
              <div className="card-content row" style={{ height: 68 }}>
                <div className="d-flex col align-items-center">
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      marginLeft: -15,
                    }}
                    onClick={this.loadPreviousEarnings}
                  >
                    <ArrowLeft size={20} color="orange" />
                  </Button>
                  <div className="col text-start">
                    <label>Current Week</label>
                    <h6 style={{ fontWeight: "normal", fontSize: 14 }}>
                      {new Date(
                        Object.keys(this.state.earnings)[0]
                      ).toLocaleString("en", {
                        month: "short",
                        day: "numeric",
                      }) +
                        " - " +
                        new Date(
                          Object.keys(this.state.earnings)[
                          Object.keys(this.state.earnings).length - 1
                          ]
                        ).toLocaleString("en", {
                          month: "short",
                          day: "numeric",
                        })}
                    </h6>
                  </div>
                </div>
                <div className="d-flex col align-items-center">
                  <div className="col text-end">
                    <label style={{ fontWeight: "normal", fontSize: 14 }}>Earnings</label>
                    <h5 className="text-header">
                      $
                      {Object.values(this.state.earnings)
                        .reduce((a, b) => a + b.value, 0)
                        .toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                    </h5>
                  </div>
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      marginRight: -15,
                    }}
                    onClick={this.loadNextEarnings}
                  >
                    <ArrowRight size={20} color="orange" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-status mt-2">
              <h4 className="text-header p-1" style={{ marginLeft: 15 }}>
                Menu
              </h4>
              <dir className="row m-1">
                <Link to={"/filters"} className="nav-link buttons-home">
                  <Filter size={16} style={{ marginBottom: 1 }} />
                  <strong> FILTERS</strong>
                </Link>
                <Link to={"/logs"} className="nav-link buttons-home">
                  <List size={18} style={{ marginBottom: 2 }} />
                  <strong> LOGS</strong>
                </Link>
                <Link to={"/calendar"} className="nav-link buttons-home">
                  <Calendar size={16} style={{ marginBottom: 3 }} />
                  <strong> CALENDAR</strong>
                </Link>
                <Link to={"/membership"} className="nav-link buttons-home">
                  <CreditCard size={16} style={{ marginBottom: 2 }} />
                  <strong> MEMBERSHIP</strong>
                </Link>
                <Link
                  onClick={this.openModalSupport}
                  className="nav-link buttons-home"
                >
                  <MessageSquare size={16} />
                  <strong> SUPPORT</strong>
                </Link>
                <Link to={"/rewards"} className="nav-link buttons-home">
                  <Gift size={16} style={{ marginBottom: 3 }} />
                  <strong> REWARDS</strong>
                </Link>
              </dir>
              <div className="btn-footer">
                <div className="d-flex justify-content-center">
                  {this.state.needSync ? (
                    <div className="card-atention text-center row">
                      <label>
                        Your account is not synced with Amazon Account.
                      </label>
                      <br />
                      <label>Go to Profile page to sync.</label>
                    </div>
                  ) : this.state.active ? (
                    !this.state.needsAmazonValidation ? (
                      this.state.filters === undefined ||
                        this.state.filters.length > 0 ? (
                        <button
                          className={
                            this.state.status === "STOPPED"
                              ? "form-control btn btn-dark"
                              : "form-control btn btn-danger"
                          }
                          style={{ fontWeight: "bold", width: 860 }}
                          onClick={this.handleClick}
                        >
                          {this.state.status === "STOPPED" ? (
                            <Search
                              size={17}
                              style={{ marginBottom: 3, marginRight: 10 }}
                            />
                          ) : (
                            <Search
                              className="search-animation"
                              size={17}
                              style={{ marginBottom: 3, marginRight: 10 }}
                            />
                          )}

                          {this.state.filters === undefined ||
                            this.state.status === "LOADING"
                            ? "LOADING"
                            : this.state.status === "STOPPED"
                              ? "START SEARCH"
                              : "STOP SEARCH"}
                        </button>
                      ) : (
                        <div className="card-atention text-center">
                          <label>
                            Please add or enable some filters to start using
                            FastFlex!
                          </label>
                        </div>
                      )
                    ) : (
                      <Form
                        style={{ fontWeight: "bold", width: 860 }}
                        onSubmit={this.handleValidation}
                        ref={(c) => {
                          this.form = c;
                        }}
                      >
                        <button className="form-control btn btn-pages">
                          <strong>VALIDATED ON AMAZON APP</strong>
                        </button>
                      </Form>
                    )
                  ) : this.state.membershipValid ? (
                    <div className="card-atention text-center row">
                      <label>Your account is deactivated.</label>
                      <br />
                      <label>
                        Please contact admin to activate your account.
                      </label>
                    </div>
                  ) : (
                    <div className="card-atention text-center row">
                      <label>Your membership has expired!</label>
                      <br />
                      <label>Please go to Membership page to renew.</label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
