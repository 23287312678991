import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "fastflex/";

class FastflexService {
  getLogs() {
    return axios.get(API_URL + "logs", { headers: authHeader() });
  }

  getAcceptLogs() {
    return axios.get(API_URL + "accept_logs", { headers: authHeader() });
  }

  getLastOffers() {
    return axios.get(API_URL + "last_offers", { headers: authHeader() });
  }

  clearLogs() {
    return axios.delete(API_URL + "logs", { headers: authHeader() });
  }

  start() {
    return axios.get(API_URL + "start", { headers: authHeader() });
  }

  stop() {
    return axios.get(API_URL + "stop", { headers: authHeader() });
  }

  stopClient(userId) {
    return axios.get(API_URL + "stop_client/" + userId, {
      headers: authHeader(),
    });
  }

  getStatus() {
    return axios.get(API_URL + "status", { headers: authHeader() });
  }

  validate(amazonUrl) {
    return axios.post(
      API_URL + "validate",
      { amazonUrl: amazonUrl },
      { headers: authHeader() }
    );
  }

  getUsingNow() {
    return axios.get(API_URL + "using_now", { headers: authHeader() });
  }
}

export default new FastflexService();
