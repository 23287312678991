import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isAlphanumeric } from "validator";
import { User, Lock, Mail, Phone, UserPlus, Key } from "react-feather";

import AuthService from "../../services/auth.service";
import { Link, Redirect } from "react-router-dom";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const phone = (value) => {
  if (value.length !== 12 || value.substring(0, 2) !== "+1") {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        This is not a valid phone. Put +1 before your number. Example
        +11231231234
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
  if (!isAlphanumeric(value)) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        The username must contain only letters and numbers.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeAcceptedTerms = this.onChangeAcceptedTerms.bind(this);
    this.onChangeIndicationCode = this.onChangeIndicationCode.bind(this);

    this.state = {
      username: "",
      phone: "",
      email: "",
      password: "",
      successful: false,
      message: "",
      redirect: undefined,
      acceptedTerms: false,
      startWithIndicationCode: false,
      indicationCode: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeIndicationCode(e) {
    this.setState({
      indicationCode: e.target.value,
    });
  }

  onChangeAcceptedTerms(e) {
    this.setState({
      acceptedTerms: e.target.checked,
    });
  }

  componentDidMount() {
    const attribute = this.props.location.search.replace("?", "").split("=");
    if (attribute.length < 2) return;
    const code = attribute[1];
    if (!code) return;
    this.setState({
      startWithIndicationCode: true,
      indicationCode: code,
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    if (!this.state.acceptedTerms) {
      this.setState({
        successful: false,
        message:
          "You must accept the terms and conditions in order to complete registering!",
      });
      return;
    }

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(
        this.state.username,
        this.state.phone,
        this.state.email,
        this.state.password,
        this.state.indicationCode
      ).then(
        (response) => {
          this.setState({
            successful: true,
            message:
              response.data.message + " Redirecting you to sync process...",
            loginUrl: response.data.loginUrl,
          });
          setTimeout(() => {
            this.setState({
              redirect: "/sync",
            });
          }, 3000);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
            loginUrl: this.state.loginUrl,
            username: this.state.username,
          }}
        />
      );
    }

    return (
      <div className="row d-flex justify-content-center mt-5">
        <div className="card-login card-body mb-5">
          <div className="mb-4">
            <h3 className="text-header">New here?</h3>
            <label>Signing up is easy. It only takes a few steps</label>
          </div>
          <Form
            onSubmit={this.handleRegister}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="username">
                    <User size={22} /> Username
                  </label>
                  <Input
                    type="text"
                    className="form-control mt-2 ipt"
                    name="username"
                    value={this.state.username}
                    onChange={this.onChangeUsername}
                    validations={[required, vusername]}
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="phone">
                    <Phone size={20} /> Phone Number
                  </label>
                  <Input
                    type="phone"
                    className="form-control mt-2 ipt"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.onChangePhone}
                    validations={[required, phone]}
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="email">
                    <Mail size={20} /> Email
                  </label>
                  <Input
                    type="text"
                    className="form-control mt-2 ipt"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                    validations={[required, email]}
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="password">
                    <Lock size={20} /> Password
                  </label>
                  <Input
                    type="password"
                    className="form-control mt-2 ipt"
                    name="password"
                    value={this.state.password}
                    onChange={this.onChangePassword}
                    validations={[required, vpassword]}
                  />
                </div>

                <div className="form-group mt-3">
                  <label htmlFor="indicationCode">
                    <Key size={22} /> Referral Code
                  </label>
                  <Input
                    type="text"
                    className="form-control mt-2 ipt"
                    name="indicationCode"
                    value={this.state.indicationCode}
                    onChange={this.onChangeIndicationCode}
                    disabled={this.state.startWithIndicationCode}
                  />
                </div>

                <div className="d-flex text-center justify-content-center">
                  <div className="mt-4 div-cb">
                    <input
                      className="cb"
                      type="checkbox"
                      name="acceptedTerms"
                      checked={this.state.acceptedTerms}
                      onClick={this.onChangeAcceptedTerms}
                      style={{ width: 20, height: 20 }}
                    />
                    <label>
                      I Accept all
                      <Link to={"/terms"} className="text-link">
                        <strong> Terms and Conditions</strong>
                      </Link>
                    </label>
                  </div>
                </div>

                <div className="form-group mt-4">
                  <button className="form-control btn btn-dark btn-block">
                    <UserPlus size={17} />
                    <strong> REGISTER</strong>
                  </button>
                </div>
              </div>
            )}

            {this.state.message && (
              <div className="form-group mt-3">
                <div
                  className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  }
                  role="alert"
                >
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>
          <div className="mt-4 d-flex justify-content-center">
            <label>
              Already have an account?
              <Link to={"/login"} className="text-link">
                <strong> LOGIN</strong>
              </Link>
            </label>
          </div>
        </div>
      </div>
    );
  }
}
