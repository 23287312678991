import React, { Component } from "react";
import { 
  Check, 
  DollarSign, 
  PhoneIncoming, 
  UserPlus, 
  Users, 
  Zap 
} from "react-feather";
import AuthService from "../../services/auth.service";

export default class DashAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });
  }

  render() {
    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            <div className="row mt-2">
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <UserPlus size={20}/> Accounts
                </h4>
                <h1>17</h1>
                <label>Registered Today - 3</label>
              </div>
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <Users size={20}/> Memberships
                </h4>
                <h1>12</h1>
                <label>New/Renew Today - 2</label>
              </div>
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <Zap size={20}/> Bots Running
                </h4>
                <h1>5</h1>
                <label>Running Now</label>
              </div>
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <Check size={20}/> Accepted Offers
                </h4>
                <h1>572</h1>
                <label>Accepted Today - 22</label>
              </div>
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <PhoneIncoming size={20}/> Twilio Balance
                </h4>
                <h1>$9.16</h1>
              </div>
              <div className="card-features text-center mt-2">
                <h4 className="text-header">
                  <DollarSign size={20}/> Stripe Balance
                </h4>
                <h1>$255.00</h1>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
