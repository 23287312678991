import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "filters/";

class FiltersService {
  getStations() {
    return axios.get(API_URL + "stations", { headers: authHeader() });
  }

  getFilters(onlyEnabled) {
    return axios.get(API_URL + "?onlyEnabled=" + onlyEnabled, {
      headers: authHeader(),
    });
  }

  create(
    amazonId,
    stationName,
    minPrice,
    minHourPrice,
    minArrivalTime,
    startTime,
    endTime
  ) {
    return axios.post(
      API_URL,
      {
        amazonId,
        stationName,
        minPrice,
        minHourPrice,
        minArrivalTime,
        startTime,
        endTime,
      },
      { headers: authHeader() }
    );
  }

  delete(stationId) {
    return axios.delete(API_URL + stationId, { headers: authHeader() });
  }

  toggleEnabled(userId, stationId, enabled) {
    return axios.post(
      API_URL + "toggleEnabled",
      {
        userId,
        stationId,
        enabled,
      },
      { headers: authHeader() }
    );
  }
}

export default new FiltersService();
