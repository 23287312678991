import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "earnings/";

class EarningsService {
  getEarnings(currentWeek) {
    return axios.get(API_URL + "?currentWeek=" + currentWeek, {
      headers: authHeader(),
    });
  }
}

export default new EarningsService();
