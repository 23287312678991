import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import {
  User,
  Home,
  Filter,
  List,
  LogOut,
  LogIn,
  UserPlus,
  Calendar,
  Settings,
  CreditCard,
  Gift,
} from "react-feather";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";

import AuthService from "./services/auth.service";

import Homepage from "./components/home.component";
import Filters from "./components/filters.component";
import Logs from "./components/logs.component";
import Schedule from "./components/calendar.component";
import Membership from "./components/membership.component";
import Rewards from "./components/rewards.component";
import Dashboard from "./components/dashboard.component";
import Terms from "./components/terms.component";
import Privacy from "./components/privacy.component";

import Login from "./components/auth/login.component";
import Register from "./components/auth/register.component";
import Sync from "./components/auth/sync.component";
import Profile from "./components/auth/profile.component";

import DashAdmin from "./components/admin/dashadmin.component";
import AdmSettings from "./components/admin/settings.component";
import Clients from "./components/admin/clients.component";
import Client from "./components/admin/client.component";
import Debug from "./components/admin/debug.component";
import Out from "./components/admin/out.component";
import Error from "./components/admin/error.component";

import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import { NavDropdown } from "react-bootstrap";

class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      isModerator: false,
      isAdmin: false,
      currentUser: undefined,
      isNavExpanded: false,
    };

    this.setIsNavExpanded = (isNavExpanded) => {
      this.setState({ isNavExpanded: isNavExpanded });
    };

    this.handleClick = (e) => {
      if (
        this.node.contains(e.target) ||
        (e.target.text !== undefined && e.target.text.includes("ADMIN"))
      ) {
        // if clicked inside menu do something
      } else {
        // If clicked outside menu, close the navbar.
        this.setState({ isNavExpanded: false });
      }
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        isModerator: user.roles.includes("ROLE_MODERATOR"),
        isAdmin: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });

    document.addEventListener("click", this.handleClick, false);
  }

  componentWillUnmount() {
    EventBus.remove("logout");
    document.removeEventListener("click", this.handleClick, false);
  }

  logOut() {
    AuthService.logout();
    this.setState({
      isModerator: false,
      isAdmin: false,
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser, isAdmin } = this.state;

    return (
      <div ref={(node) => (this.node = node)}>
        <header className="header">
          <Navbar
            bg="black"
            variant="dark"
            expand="lg"
            collapseOnSelect
            onToggle={this.setIsNavExpanded}
            expanded={this.state.isNavExpanded}
          >
            <Container fluid>
              <Navbar.Brand>
                <div
                  onClick={() => {
                    window.location = currentUser ? "/dashboard" : "/";
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <h1 className="navbar-brand m-1">
                    <img src="favicon.ico" alt="" className="icon-menu" />{" "}
                    FastFlex
                  </h1>
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-lg`}
                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    <h4 className="text-link">FastFlex</h4>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {currentUser && (
                    <Nav className="justify-content-end flex-grow-1">
                      <Nav.Link>
                        <Link to={"/dashboard"} className="nav-link">
                          <Home size={22} />
                          <strong> DASHBOARD</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/filters"} className="nav-link">
                          <Filter size={22} />
                          <strong> FILTERS</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/logs"} className="nav-link">
                          <List size={22} />
                          <strong> LOGS</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/calendar"} className="nav-link">
                          <Calendar size={22} />
                          <strong> CALENDAR</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/membership"} className="nav-link">
                          <CreditCard size={22} />
                          <strong> MEMBERSHIP</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/rewards"} className="nav-link">
                          <Gift size={22} />
                          <strong> MY REWARDS</strong>
                        </Link>
                      </Nav.Link>
                      <Nav.Link>
                        <Link to={"/profile"} className="nav-link">
                          <User size={22} />
                          <strong> PROFILE</strong>
                        </Link>
                      </Nav.Link>
                      {isAdmin && (
                        <div className="nav-link">
                          <NavDropdown
                            id="nav-dropdown-dark-example"
                            title={
                              <>
                                <User size={22} />
                                <strong> ADMIN</strong>
                              </>
                            }
                            menuVariant="dark"
                          >
                            <NavDropdown.Item>
                              <Link to={"/dashadmin"} className="nav-link">
                                <Home size={22} />
                                <strong> DASHBOARD</strong>
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to={"/settings"} className="nav-link">
                                <Settings size={22} />
                                <strong> SETTINGS</strong>
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to={"/clients"} className="nav-link">
                                <User size={22} />
                                <strong> CLIENTS</strong>
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item>
                              <Link
                                to={"/debug/" + currentUser.id}
                                className="nav-link"
                              >
                                <List size={22} />
                                <strong> DEBUG</strong>
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to={"/out"} className="nav-link">
                                <List size={22} />
                                <strong> OUT</strong>
                              </Link>
                            </NavDropdown.Item>
                            <NavDropdown.Item>
                              <Link to={"/error"} className="nav-link">
                                <List size={22} />
                                <strong> ERROR</strong>
                              </Link>
                            </NavDropdown.Item>
                          </NavDropdown>
                        </div>
                      )}
                    </Nav>
                  )}

                  {currentUser ? (
                    <Nav className="justify-content-end flex-grow-1">
                      <Nav.Link>
                        <Link
                          to="/login"
                          className="nav-button buttons-menu form-control"
                          onClick={this.logOut}
                        >
                          <LogOut size={20} />
                          <strong> LOGOUT</strong>
                        </Link>
                      </Nav.Link>
                    </Nav>
                  ) : (
                    <Nav className="justify-content-end flex-grow-1">
                      <Nav.Link>
                        <Link
                          to={"/login"}
                          className="nav-button buttons-menu form-control"
                        >
                          <LogIn size={20} />
                          <strong> LOGIN</strong>
                        </Link>
                      </Nav.Link>

                      <Nav.Link>
                        <Link
                          to={"/register"}
                          className="nav-button buttons-menu form-control"
                        >
                          <UserPlus size={20} />
                          <strong> REGISTER</strong>
                        </Link>
                      </Nav.Link>
                    </Nav>
                  )}
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </header>
        <div className="container">
          <Switch>
            <Route exact path={["/", "/home"]} component={Homepage} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/sync" render={(props) => <Sync {...props} />} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/filters" component={Filters} />
            <Route exact path="/logs" component={Logs} />
            <Route exact path="/calendar" component={Schedule} />
            <Route exact path="/membership" component={Membership} />
            <Route exact path="/rewards" component={Rewards} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/dashadmin" component={DashAdmin} />
            <Route exact path="/settings" component={AdmSettings} />
            <Route exact path="/clients" component={Clients} />
            <Route exact path="/client/:id" component={Client} />
            <Route exact path="/debug/:id" component={Debug} />
            <Route exact path="/out" component={Out} />
            <Route exact path="/error" component={Error} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
          </Switch>
        </div>
        {<AuthVerify logOut={this.logOut} />}
      </div>
    );
  }
}

export default App;
