import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "membership/";

class MembershipService {
  start(selectedPlan) {
    return axios.post(
      API_URL + "start",
      { selectedPlan },
      { headers: authHeader() }
    );
  }

  getIndications() {
    return axios.get(API_URL + "indications", { headers: authHeader() });
  }
}

export default new MembershipService();
