import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "intervals/";

class IntervalsService {
  getIntervals(onlyEnabled) {
    return axios.get(API_URL + "?onlyEnabled=" + onlyEnabled, {
      headers: authHeader(),
    });
  }

  create(startTime, endTime) {
    return axios.post(
      API_URL,
      {
        startTime,
        endTime,
      },
      { headers: authHeader() }
    );
  }

  delete(id) {
    return axios.delete(API_URL + id, { headers: authHeader() });
  }

  toggleEnabled(id, enabled) {
    return axios.post(
      API_URL + "toggleEnabled",
      {
        id,
        enabled,
      },
      { headers: authHeader() }
    );
  }
}

export default new IntervalsService();
