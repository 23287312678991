import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service";
import LogsService from "../../services/logs.service";

export default class Debug extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      currentUser: { username: "" },
      logs: "",
      counter: 10,
    };

    this.intervalID = 0;
  }

  loadLogs() {
    LogsService.getDebug(this.props.match.params.id)
      .then((response) => {
        this.setState({ logs: response.data, counter: 10 });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ counter: 10 });
      });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/home" });
    this.setState({ currentUser: currentUser });

    this.loadLogs();

    this.intervalID = setInterval(() => {
      if (this.state.counter > 0 && this.logs !== "") {
        this.setState({ counter: this.state.counter - 1 });
      } else if (this.state.counter === 0) {
        this.loadLogs();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            <div className="d-flex justify-content-center">
              <h4 className="text-header">DEBUG</h4>
            </div>
            <label>
              Refreshing Debug Log in <strong>{this.state.counter}</strong>s
            </label>
            <ul className="list-group mt-1">
              {this.state.logs
                .split("[")
                .slice(1)
                .reverse()
                .map((item) => {
                  const parts = item.split("]");
                  var date = parts[0];
                  if (date.includes("Z")) {
                    date = new Date(date).toLocaleString();
                  }
                  if (parts[1].includes("<a>")) {
                    const links = parts[1].split("<a>");
                    return (
                      <li
                        className="list-group-item"
                        style={{ whiteSpace: "pre-line" }}
                      >
                        {"[" + date + "]" + links[0]}
                        <a href={links[1]} target="_blank" rel="noreferrer">
                          {links[2]}
                        </a>
                        {links[3]}
                      </li>
                    );
                  }
                  return (
                    <li
                      className="list-group-item"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {"[" + date + "]" + parts[1]}
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}
