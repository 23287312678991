import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "logs/";

class LogsService {
  getOut() {
    return axios.get(API_URL + "out", { headers: authHeader() });
  }

  getError() {
    return axios.get(API_URL + "error", { headers: authHeader() });
  }

  getDebug(id) {
    return axios.get(API_URL + "debug/" + id, { headers: authHeader() });
  }
}

export default new LogsService();
