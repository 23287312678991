import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL + "users/";

class UserService {
  getUsers() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  getUser(userId) {
    return axios.get(API_URL + userId, { headers: authHeader() });
  }

  getSelf() {
    return axios.get(API_URL + "current", { headers: authHeader() });
  }

  delete(userId) {
    return axios.delete(API_URL + userId, { headers: authHeader() });
  }

  update(
    id,
    username,
    phone,
    email,
    password,
    requestInterval,
    pauseInterval,
    useTurboSearch,
    amazonRefreshToken,
    amazonId,
    expirationDate,
    stopAfterAcceptOffer,
    active,
    needsAmazonValidation,
    indicationCode,
    hasManualCaptcha
  ) {
    return axios.put(
      API_URL,
      {
        id: id,
        username,
        phone,
        email,
        password,
        requestInterval,
        pauseInterval,
        useTurboSearch,
        amazonRefreshToken,
        amazonId,
        expirationDate,
        stopAfterAcceptOffer,
        active,
        needsAmazonValidation,
        indicationCode,
        hasManualCaptcha,
      },
      { headers: authHeader() }
    );
  }

  updateSelf(
    username,
    phone,
    email,
    password,
    requestInterval,
    pauseInterval,
    useTurboSearch,
    stopAfterAcceptOffer
  ) {
    return axios.put(
      API_URL + "current",
      {
        username,
        phone,
        email,
        password,
        requestInterval,
        pauseInterval,
        useTurboSearch,
        stopAfterAcceptOffer,
      },
      { headers: authHeader() }
    );
  }
}

export default new UserService();
