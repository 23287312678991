/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/bot.png";
import devices from "../assets/devices.png";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.goToRegister = this.goToRegister.bind(this);

    this.state = {
      redirect: null,
      currentUser: null,
    };
  }

  goToRegister() {
    window.location = "/register";
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="">
          <div className="mt-3 row d-flex text-center justify-content-center">
            <img className="bot-img" src={logo} alt="bot-fastflex" />
            <h2 className="mt-3">👋 Hey, Amazon Flex Drivers ✌️</h2>
            <h3 className="">Grab Offers Quickly and Automatically</h3>
            <strong className="mt-1">
              Capture Blocks Before Your Phone See's Them
            </strong>
          </div>
          <div className="mt-5 row d-flex text-center justify-content-center">
            <button
              className="form-control btn-home btn btn-dark btn-block"
              onClick={this.goToRegister}
            >
              <strong>REGISTER NOW</strong>
            </button>
            <div className="mb-5">
              <label className="mt-2">
                No Credit Card Required To Register
              </label>
            </div>
            <label className="mt-5">
              Compatible with Apple and Android devices
            </label>
            <div className="mb-5">
              <img
                className="mt-3 devices-img"
                src={devices}
                alt="Apple and Android"
              />
            </div>
          </div>
          <div className="mt-5 row d-flex text-center justify-content-center">
            <h1 className="text-header">Features</h1>
            <label>
              Our aim is to provide you with on-demand services to help you
              search and capture Amazon Flex offers. All managed by yourself.
            </label>
          </div>
          <div className="row mt-2">
            <div className="card-features text-center mt-2">
              <h4 className="text-header">Fully Automated</h4>
              <label>
                Capture blocks with our system, set your filters and press start
                to capture blocks.
              </label>
            </div>
            <div className="card-features text-center mt-2">
              <h4 className="text-header">Custom Filters</h4>
              <label>
                Accept only the blocks you want by using our filter options such
                as Stations, Price, Arrival Time, etc...
              </label>
            </div>
            <div className="card-features text-center mt-2">
              <h4 className="text-header">SMS and E-mail</h4>
              <label>
                We’ll send you a SMS and E-mail text message to your phone when
                you have a block to work.
              </label>
            </div>
            {/* <div className="card-features text-center mt-2">
              <h4 className="text-header">Captcha By Pass</h4>
              <label>
                Maximize your opportunities without worrying about solving the
                puzzle. Our bot will solve it for you.
              </label>
            </div> */}
            <div className="card-features text-center mt-2">
              <h4 className="text-header">Customer Support</h4>
              <label>
                Our official support is here to help you with any concerns you
                may have. We got you.
              </label>
            </div>
          </div>
          <div className="mt-5 row d-flex text-center justify-content-center">
            <h1 className="text-header">FAQ</h1>
            <label>Frequently asked questions about the system.</label>
          </div>
          <div>
            <h4 className="mt-4 text-header">Will my account be safe?</h4>
            <label>
              Yes! Rest assured that your account is in great hands, we've taken
              countless measures to make sure your account goes undetected. In
              fact, we can boast a record of two years in operation without a
              single deactivation.
            </label>
            <hr />
            <h4 className="text-header">What filters are available?</h4>
            <label>
              We've created filters to meet every need, there are filters for
              Stations, Price, Time to Arrive and more.
            </label>
            <hr />
            <h4 className="text-header">Android and iOS compatible?</h4>
            <label>
              Yes! Our system can be accessed and controlled from any device
              that has a web browser not only iOS and Android but also laptops
              and tablets as well.
            </label>
            <hr />
            <h4 className="text-header">Can I work 40 hours a week?</h4>
            <label>
              Yes! The majority of our users get the hours that they want each
              week.
            </label>
            <hr />
          </div>
          <div className="mt-5 row d-flex text-center justify-content-center">
            <button
              className="form-control btn-home btn btn-dark btn-block"
              onClick={this.goToRegister}
            >
              <strong>REGISTER NOW</strong>
            </button>
            <div className="mb-5">
              <label className="mt-2">
                No Credit Card Required To Register
              </label>
            </div>
            <div className="mt-5">
              <Link to={"/terms"} className="nav-button buttons-menu m-1">
                Conditions of Use
              </Link>
              <Link to={"/privacy"} className="nav-button buttons-menu m-1">
                Privacy Notice
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
