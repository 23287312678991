/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../../services/auth.service";

import Button from "react-bootstrap/Button";

export default class Sync extends Component {
  constructor(props) {
    super(props);
    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.finishRegister = this.finishRegister.bind(this);
    this.onChangeAmazonUrl = this.onChangeAmazonUrl.bind(this);

    this.state = {
      redirect: null,
      currentUser: null,
      successful: false,
      message: "",
      amazonUrl: "",
    };
  }

  redirectToLogin() {
    window.open(this.state.loginUrl, "_blank").focus();
  }

  onChangeAmazonUrl(e) {
    this.setState({
      amazonUrl: e.target.value,
    });
  }

  handleFinishResponse(response) {
    this.setState({
      successful: true,
      message: response.data.message + " Redirecting you to login...",
    });
    setTimeout(() => {
      this.setState({
        redirect: "/login",
      });
    }, 3000);
  }

  handleFinishError(error) {
    const resMessage =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString();

    this.setState({
      successful: false,
      message: resMessage,
    });
  }

  finishRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    if (this.props.location.loginUrl) {
      AuthService.finishRegister(
        this.props.location.username,
        this.state.amazonUrl
      ).then(
        (response) => {
          this.handleFinishResponse(response);
        },
        (error) => {
          this.handleFinishError(error);
        }
      );
    } else {
      AuthService.finishSync(
        this.state.amazonUrl
      ).then(
        (response) => {
          this.handleFinishResponse(response);
        },
        (error) => {
          this.handleFinishError(error);
        }
      );
    }
  }

  componentDidMount() {
    if (this.props.location.loginUrl) {
      this.setState({
        loginUrl: this.props.location.loginUrl,
      });
    } else {
      AuthService.getSyncInfo().then((response) => {
        this.setState({
          loginUrl: response.data.loginUrl,
        });
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={{ pathname: this.state.redirect }} />;
    }

    return (
      <div className="container">
        <div className="">
          <div className="d-flex justify-content-center">
            <h4 className="text-header">ACCOUNT SYNC</h4>
          </div>
          <hr />
          <div className="mt-3 mb-5">
            <h5>Step 1.</h5>
            <br />
            <label>
              Use your Amazon Flex credentials to log in. A non functioning
              Amazon page will appear afterwards.
            </label>

            <Button
              className="btn-dark form-control mt-4"
              onClick={this.redirectToLogin}
            >
              <strong>LOGIN WITH AMAZON FLEX</strong>
            </Button>
          </div>
          <hr />
          <div className="mt-3">
            <h5>Step 2.</h5>
            <br />
            <label>
              Copy and paste the URL link of the non functioning Amazon page
              where you got redirected to.
            </label>
            <input
              type="text"
              className="form-control ipt mt-4"
              placeholder="Paste URL link here"
              value={this.state.amazonUrl}
              onChange={this.onChangeAmazonUrl}
            />
            <Button
              className="btn-dark form-control mt-4"
              onClick={this.finishRegister}
            >
              <strong>SYNC ACCOUNT</strong>
            </Button>
          </div>

          {this.state.message && (
            <div className="form-group mt-3">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
