import React, { Component } from "react";
import { Clock, Edit, Phone, Upload } from "react-feather";
import AuthService from "../../services/auth.service";

export default class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: null,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });
  }

  render() {
    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            <div className="card">
              <h5 className="text-header text-center">Twilio Settings</h5>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="twilioSid">
                  <Edit size={20} /> Twilio SID
                </label>
                <input
                  placeholder="ACed8c7b5e7a2a6acbccc312b7c2c5bb2e"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="twilioSid"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="twilioToken">
                  <Edit size={20} /> Twilio Token
                </label>
                <input
                  placeholder="62f2a1ca414f23d04cac0a9d77b8eb94"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="twilioToken"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="twilioPhone">
                  <Phone size={20} /> Twilio Phone
                </label>
                <input
                  placeholder="+14433232304"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="twilioPhone"
                />
              </div>
            </div>

            <div className="mt-3 card">
              <h5 className="text-header text-center">Turbo Mode Settings</h5>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="maxRequests">
                  <Clock size={20} /> Max Requests
                </label>
                <input
                  placeholder="0"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="maxRequests"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="pauseRequests">
                  <Clock size={20} /> Pause Requests (minutes)
                </label>
                <input
                  placeholder="30"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="pauseRequests"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="requestInterval">
                  <Clock size={20} /> Request Interval (seconds)
                </label>
                <input
                  placeholder="1"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="requestInterval"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="pauseInterval">
                  <Clock size={20} /> Auto Resume Sleep Time (minutes)
                </label>
                <input
                  placeholder="90"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="pauseInterval"
                />
              </div>
            </div>
            
            <div className="mt-3 card">
              <h5 className="text-header text-center">New Register Default Settings</h5>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="requestInterval">
                  <Clock size={20} /> Test Bot (days)
                </label>
                <input
                  placeholder="1"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="requestInterval"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="requestInterval">
                  <Clock size={20} /> Request Interval to Manual Mode
                </label>
                <input
                  placeholder="1"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="requestInterval"
                />
              </div>

              <div className="form-group mt-3">
                <label className="text-basic" htmlFor="pauseInterval">
                  <Clock size={20} /> Auto Resume Sleep Time to Manual Mode
                </label>
                <input
                  placeholder="90"
                  type="number"
                  className="form-control mt-2 ipt"
                  name="pauseInterval"
                />
              </div>

              <div className="d-flex">
                <div className="mt-2 div-cb">
                  <input
                    className="cb"
                    type="checkbox"
                    name="stopAfterAcceptOffer"
                    checked={this.state.stopAfterAcceptOffer}
                    onClick={this.onChangeStopAfterAcceptOffer}
                    style={{ width: 20, height: 20 }}
                  />
                  <label className="text-basic" htmlFor="stopAfterAcceptOffer">
                    Stop After Accept Offer
                  </label>
                </div>
              </div>
            </div>

            <div className="form-group mt-4">
              <button className="form-control btn btn-pages btn-block">
                <Upload size={17} />
                <strong> UPDATE</strong>
              </button>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
