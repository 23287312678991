/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Info, Trash2, X } from "react-feather";
import { Modal } from "react-bootstrap";
import ScheduledOffersService from "../services/scheduledOffers.service";

export default class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
      currentUser: null,
      isOpen: false,
      offers: [],
      message: "",
      successful: false,
      selectedId: "",
      isLoading: true
    };
  }

  loadOffers() {
    ScheduledOffersService.getScheduledOffers().then((response) => {
      this.setState({ offers: response.data || [] });
    });
  }

  deleteFilter(offerId) {
    this.setState({
      message: "",
      successful: false,
    });

    ScheduledOffersService.delete(offerId).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
        });
        this.loadOffers();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: resMessage,
          successful: false,
        });
        this.resetMessage()
      }
    );
  }

  resetMessage() {
    setTimeout(() => {
      this.setState({
        message: "",
        successful: false,
      });
    }, 2000);
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500)

    this.loadOffers();
  }

  openModal = (offerId) => this.setState({ selectedId: offerId, isOpen: true });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    var offers = (
      <div className="card-calendar mt-3">
        <Info size={18} /> There are no scheduled offers for the moment, start searching for offers
        on Dashboard page.
      </div>
    );
    if (this.state.offers && this.state.offers.length > 0) {
      offers = this.state.offers.map((offer) => {
        return (
          <div className="card-calendar mt-2">
            <Card.Text>
              <strong>📍 {offer.station}</strong>
              <br />
              <label>
                💵 Price: ${offer.price}&nbsp;&nbsp; 💰 ${offer.hourPrice} per
                hour
              </label>
              <br />
              <label>
                🗓 Date: {offer.date}&nbsp;&nbsp; ⏰ {offer.timeToStart} to start
              </label>
              <br />
              <label>
                🕙 Time: {offer.time}&nbsp;&nbsp; ⏳ {offer.duration}
                hrs
              </label>
            </Card.Text>
            <Button
              variant="danger form-control"
              onClick={() => {
                this.openModal(offer.id);
              }}
            >
              <Trash2 size={15} style={{ marginBottom: 3 }} />
              <strong> FORFEIT THIS BLOCK</strong>
            </Button>
          </div>
        );
      });
    }

    return (
      <div className="container">
        {this.state.isLoading && <Loader />}
        <div className="">
          <div className="d-flex justify-content-center">
            <h4 className="text-header">CALENDAR</h4>
          </div>
          {offers}
          {this.state.message && (
            <div className="form-group mt-3">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}
              </div>
            </div>
          )}
          <Modal show={this.state.isOpen} onHide={this.closeModal} centered>
            <Modal.Body>
              <div className="col d-flex justify-content-between">
                <h5 className="text-header mt-2">
                  <Trash2 size={17} /> Forfeit Block
                </h5>
                <button className="text-header" onClick={this.closeModal}>
                  <X size={20} />
                </button>
              </div>
              <hr />
              <label>Do you really want to forfeit this block?</label>
              <hr />
              <div className="mt-3 d-flex justify-content-between ">
                <button
                  className="btn btn-primary form-control m-2"
                  onClick={() => {
                    this.deleteFilter(this.state.selectedId);
                    this.closeModal();
                  }}
                >
                  <strong>YES</strong>
                </button>
                <button
                  className="btn btn-danger form-control mt-2"
                  onClick={this.closeModal}
                >
                  <strong>CANCEL</strong>
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
