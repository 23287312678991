/* eslint-disable jsx-a11y/accessible-emoji */
import React, { Component } from "react";
import { Clock, CreditCard, ShoppingBag } from "react-feather";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";
import MembershipService from "../services/membership.service";
import UserService from "../services/user.service";
import Select from "react-validation/build/select";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";

const required = (value) => {
  if (!value) {
    return (
      <div
        className="alert alert-danger alert-dismissible show mt-2"
        role="alert"
      >
        This field is required!
      </div>
    );
  }
};

export default class Membership extends Component {
  constructor(props) {
    super(props);
    this.onChangePlan = this.onChangePlan.bind(this);
    this.handleCheckout = this.handleCheckout.bind(this);

    this.state = {
      redirect: null,
      currentUser: null,
      message: "",
      successful: false,
      selectedPlan: "",
      expireIn: null,
      expiredAt: null,
      paymentUrl: "",
      isLoading: true
    };
  }

  loadPaymentUrl() {
    if (!this.state.selectedPlan) return;

    this.setState({
      paymentUrl: "",
      message: undefined,
      successful: false,
    });

    MembershipService.start(this.state.selectedPlan).then(
      (response) => {
        this.setState({
          paymentUrl: response.data,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: resMessage,
          successful: false,
        });
      }
    );
  }

  loadUser() {
    UserService.getSelf().then((response) => {
      this.setState({
        username: response.data.username,
        active: response.data.active,
        membershipValid: response.data.membershipValid,
        needSync: response.data.needSync,
      });
      this.updateExpiration(response.data.expirationDate);
    });
  }

  updateExpiration(expirationDate) {
    const date = new Date(
      expirationDate.substring(0, 19).replace(" ", "T") + ".000Z"
    );
    const diffTime = Math.abs(date - new Date());
    const remainingDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const diffHours = diffTime / (1000 * 60 * 60);
    const remainingHours = Math.floor(diffHours - remainingDays * 24);
    const diffMinutes = diffTime / (1000 * 60);
    const remainingMinutes = Math.floor(
      diffMinutes - remainingDays * 24 * 60 - remainingHours * 60
    );
    const expireIn =
      remainingDays + "D " + remainingHours + "H " + remainingMinutes + "M";

    this.setState({ expireIn: expireIn, expiredAt: date.toLocaleString() });
  }

  onChangePlan(e) {
    this.setState(
      {
        selectedPlan: e.target.value,
      },
      () => {
        this.loadPaymentUrl();
      }
    );
  }

  handleCheckout(e) {
    e.preventDefault();

    if (this.state.successful) return;

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      const tab = window.open(this.state.paymentUrl, "_self");
      if (tab) tab.focus();
    }
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) this.setState({ redirect: "/" });
    this.setState({ currentUser: currentUser });

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500)

    this.loadUser();
    this.loadPaymentUrl();


    if (this.props.location.search.includes("success=true")) {
      this.setState({
        message: "Payment finished successfully!",
        successful: true,
      });
    } else if (this.props.location.search.includes("canceled=true")) {
      this.setState({
        message: "Error on finishing payment, please try again...",
        successful: false,
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    return (
      <div className="container">
        {this.state.isLoading && <Loader />}
        {this.state.currentUser ? (
          <div className="">
            <div className="d-flex justify-content-center mb-2">
              <h4 className="text-header">MEMBERSHIP</h4>
            </div>
            <div className="mt-2">
              <div className="text-center card-filter mb-4">
                <h5 style={{ marginTop: 5 }}>
                  <Clock size={20} color="orange" style={{ marginBottom: 3 }} />{" "}
                  {this.state.active
                    ? "EXPIRE IN " + this.state.expireIn
                    : this.state.membershipValid
                      ? "USER DEACTIVATED"
                      : "EXPIRED AT " + this.state.expiredAt}
                </h5>
              </div>
            </div>
            <Form
              onSubmit={this.handleCheckout}
              ref={(c) => {
                this.form = c;
              }}
            >
              <div className="mb-2">
                <label>
                  <ShoppingBag size={17} /> Select Plan
                </label>
                <Select
                  className="form-control ipt mt-1"
                  name="selectedPlan"
                  value={this.state.selectedPlan}
                  onChange={this.onChangePlan}
                  validations={[required]}
                >
                  <option value="">Choose Your Plan Here...</option>
                  <option value="plan1">7 Days - $40</option>
                  <option value="plan2">2 Weeks - $69</option>
                  <option value="plan3">4 Weeks - $139</option>
                  <option value="plan4">3 Days - $20</option>
                </Select>
              </div>
              {!this.state.successful ? (
                <button
                  type="submit"
                  className="btn btn-dark form-control mb-5"
                  disabled={!this.state.paymentUrl}
                >
                  <CreditCard size={17} />{" "}
                  <strong> PAY NOW</strong>
                </button>
              ) : null}
              {this.state.message && (
                <div className="form-group mt-3">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={(c) => {
                  this.checkBtn = c;
                }}
              />
            </Form>
            <div className="d-flex justify-content-center">
              <h4 className="text-header">PLANS</h4>
            </div>
            <div className="row">
              <div className="card-plans text-center mt-2">
                <h4 className="text-header">7 Days - $40</h4>
                <label>
                  Fully Automated, Custom Filters, <br />
                  Unlimited Captures, <br />
                  Captcha By Pass, <br />
                  E-mail and SMS Notifications, <br />
                  Calendar and Customer Support <br />
                </label>
              </div>
              <div className="card-plans text-center mt-2">
                <h4 className="text-header">2 Weeks - $69</h4>
                <label>
                  Fully Automated, Custom Filters, <br />
                  Unlimited Captures, <br />
                  Captcha By Pass, <br />
                  E-mail and SMS Notifications, <br />
                  Calendar and Customer Support <br />
                </label>
              </div>
              <div className="card-plans text-center mt-2">
                <h4 className="text-header">4 Weeks - $139</h4>
                <label>
                  Fully Automated, Custom Filters, <br />
                  Unlimited Captures, <br />
                  Captcha By Pass, <br />
                  E-mail and SMS Notifications, <br />
                  Calendar and Customer Support <br />
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
