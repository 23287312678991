import React, { Component } from "react";
import { Edit, List, StopCircle, Trash2, X } from "react-feather";

import UserService from "../../services/user.service";
import FastflexService from "../../services/fastflex.service";
import EventBus from "../../common/EventBus";
import { Modal } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

export default class Clients extends Component {
  constructor(props) {
    super(props);
    this.deleteUser = this.deleteUser.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);

    this.state = {
      users: [],
      allUsers: [],
      message: "",
      successful: false,
      isOpen: false,
      selectedUserId: undefined,
      tab: "all",
    };
  }

  loadUsers() {
    UserService.getUsers().then(
      (response) => {
        this.setState({ users: response.data, allUsers: response.data });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),
        });
        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  stopClient(userId) {
    FastflexService.stopClient(userId).then((response) => {
      this.loadUsers();
    });
  }

  componentDidMount() {
    this.loadUsers();
  }

  deleteUser() {
    if (!this.state.selectedUserId) return;

    this.setState({
      message: "",
      successful: false,
    });

    UserService.delete(this.state.selectedUserId).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
        });
        this.loadUsers();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          message: resMessage,
          successful: false,
        });
      }
    );

    this.closeModal();
  }

  onChangeTab(tab) {
    var users = this.state.allUsers;
    switch (tab) {
      case "active":
        users = this.state.allUsers.filter((u) => {
          return u.active;
        });
        break;
      case "usingNow":
        users = this.state.allUsers.filter((u) => {
          return u.usingNow;
        });
        break;
      default:
        break;
    }
    this.setState({
      tab: tab,
      users: users,
    });
  }

  openModal = (userId) =>
    this.setState({ isOpen: true, selectedUserId: userId });
  closeModal = () => this.setState({ isOpen: false });

  render() {
    const clientsList = this.state.users.map((user) => {
      return (
        <tr
          style={{
            background:
              this.state.tab === "all" && user.active ? "#242" : "none",
          }}
        >
          <th scope="row">{user.id}</th>
          <td>{user.username}</td>
          <td>{new Date(user.expirationDate).toLocaleString()}</td>
          <td>{user.active ? "Yes" : "No"}</td>
          <td>{user.usingNow ? "Yes" : "No"}</td>
          <td className="d-flex justify-content-between">
            <button
              className="btn-edit-client"
              onClick={() => {
                this.props.history.push("/client/" + user.id);
              }}
            >
              <Edit size={19} style={{ marginBottom: 3 }} />
            </button>
            <button
              className="btn-edit-debug"
              onClick={() => {
                this.props.history.push("/debug/" + user.id);
              }}
            >
              <List size={19} style={{ marginBottom: 3 }} />
            </button>
            <button
              className="btn-edit-stop"
              onClick={() => {
                this.stopClient(user.id);
              }}
            >
              <StopCircle size={21} style={{ marginBottom: 1 }} />
            </button>
            <button
              className="btn-edit-delete"
              onClick={() => {
                this.openModal(user.id);
              }}
            >
              <Trash2 size={20} style={{ marginBottom: 2 }} />
            </button>
          </td>
        </tr>
      );
    });

    return (
      <div className="container">
        <div className="">
          <div className="d-flex justify-content-center">
            <h4 className="text-header">CLIENTS</h4>
          </div>
          {this.state.message && (
            <div className="form-group mt-3">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}
              </div>
            </div>
          )}
          <Tabs
            defaultActiveKey="all"
            transition={false}
            id="justify-tab-example"
            className="mt-3"
            justify
            onSelect={this.onChangeTab}
          >
            <Tab eventKey="all" title="ALL" />
            <Tab eventKey="active" title="ONLY ACTIVE" />
            <Tab eventKey="usingNow" title="ONLY USING NOW" />
          </Tabs>
          <table class="card2 table mt-4">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Username</th>
                <th scope="col">Membership Expiration</th>
                <th scope="col">Active</th>
                <th scope="col">Using Now</th>
                <th scope="col">Options</th>
              </tr>
            </thead>
            <tbody>{clientsList}</tbody>
          </table>
          <Modal show={this.state.isOpen} onHide={this.closeModal} centered>
            <Modal.Body>
              <div className="col d-flex justify-content-between">
                <h5 className="text-header mt-2">
                  <Trash2 size={17} style={{ marginBottom: 3 }} /> Delete User
                </h5>
                <button className="text-header" onClick={this.closeModal}>
                  <X size={20} />
                </button>
              </div>
              <hr />
              <label>Do you really want to delete user?</label>
              <hr />
              <div className="mt-3 d-flex justify-content-between ">
                <button
                  className="btn btn-primary form-control m-2"
                  onClick={this.deleteUser}
                >
                  <strong>YES</strong>
                </button>
                <button
                  className="btn btn-danger form-control mt-2"
                  onClick={this.closeModal}
                >
                  <strong>CANCEL</strong>
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}
