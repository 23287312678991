import React, { Component } from "react";
import { Copy, Info } from "react-feather";
import AuthService from "../services/auth.service";
import MembershipService from "../services/membership.service";

export default class Rewards extends Component {
  constructor(props) {
    super(props);
    this.copyIndicationLink = this.copyIndicationLink.bind(this);

    this.state = {
      currentUser: null,
      indications: [],
      isLoading: true
    };
  }

  copyIndicationLink(e) {
    e.preventDefault();
    navigator.clipboard.writeText(
      "https://fastflex.app/register?code=" +
      this.state.currentUser.indicationCode
    );
  }

  loadIndications() {
    MembershipService.getIndications().then((response) => {
      console.log(response);
      this.setState({
        indications: response.data,
      });
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });

    this.loadIndications();

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500)
  }

  render() {
    const indications = this.state.indications;
    var id = indications.length + 1;
    const indicationsList = this.state.indications.map((indicatedUser) => {
      const membershipRenewed =
        indicatedUser["indicator.indications.membershipRenewed"];
      const registerDate =
        indicatedUser["indicator.indications.createdAt"]
          .substring(0, 19)
          .replace(" ", "T") + ".000Z";
      id--;
      return (
        <tr>
          <th scope="row">{id}</th>
          <td>{indicatedUser.username}</td>
          <td>{new Date(registerDate).toLocaleString()}</td>
          <td>{membershipRenewed ? "✅" : "⏳"}</td>
        </tr>
      );
    });

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    return (
      <div className="container">
        {this.state.isLoading && <Loader />}
        {this.state.currentUser ? (
          <div>
            <div className="">
              <h4 className="text-header text-center mb-3">MY REWARDS</h4>
              <br />
              <h5 className="text-header">REFER YOUR FRIENDS</h5>
              <p>
                Refer unlimited friends and earn 7 days for each one, as soon as
                your referral purchases any plan equal to or greater than 7
                days.
              </p>
              <hr />
              <h5 className="text-header">
                REFERRAL CODE:{" "}
                <label className="text-simple">
                  {" "}
                  {this.state.currentUser.indicationCode}
                </label>
              </h5>
              <hr />
              <h5 className="text-header">MY REFERRAL LINK</h5>
              <p>
                https://fastflex.app/register?code=
                {this.state.currentUser.indicationCode}
              </p>
              <button
                className="form-control btn btn-pages btn-block"
                onClick={this.copyIndicationLink}
              >
                <strong>
                  <Copy size={18} /> COPY MY REFERRAL LINK
                </strong>
              </button>
            </div>
            <div className="card-calendar mt-3">
              {this.state.indications.length > 0 ? (
                <table class="card2 table mt-4">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Indicated friend</th>
                      <th scope="col">Registration date</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>{indicationsList}</tbody>
                </table>
              ) : (
                <>
                  <Info size={20} style={{ marginBottom: 3 }} /> You still don't have any indication...{" "}
                  <br />
                  Share your referral link with your friends to begin earning
                  rewards.
                </>
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
