import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isAlphanumeric } from "validator";
import { User, Lock, Mail, Phone, Key, Clock, Upload } from "react-feather";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const phone = (value) => {
  if (value.length !== 12 || value.substring(0, 2) !== "+1") {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        This is not a valid phone. Put +1 before your number. Example
        +11231231234
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
  if (!isAlphanumeric(value)) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        The username must contain only letters and numbers.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value !== undefined && (value.length < 6 || value.length > 40)) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRequestInterval = this.onChangeRequestInterval.bind(this);
    this.onChangePauseInterval = this.onChangePauseInterval.bind(this);
    this.onChangeUseTurboSearch = this.onChangeUseTurboSearch.bind(this);
    this.onChangeAmazonRefreshToken = this.onChangeAmazonRefreshToken.bind(
      this
    );
    this.onChangeAmazonId = this.onChangeAmazonId.bind(this);
    this.onChangeExpirationDate = this.onChangeExpirationDate.bind(this);
    this.onChangeStopAfterAcceptOffer = this.onChangeStopAfterAcceptOffer.bind(
      this
    );
    this.onChangeActive = this.onChangeActive.bind(this);
    this.onChangeNeedsAmazonValidation = this.onChangeNeedsAmazonValidation.bind(
      this
    );
    this.onChangeIndicationCode = this.onChangeIndicationCode.bind(this);
    this.onChangeHasManualCaptcha = this.onChangeHasManualCaptcha.bind(this);

    this.state = {
      currentUser: null,
      username: "",
      phone: "",
      email: "",
      password: "",
      requestInterval: "",
      pauseInterval: "",
      useTurboSearch: true,
      amazonRefreshToken: "",
      amazonId: "",
      expirationDate: "",
      stopAfterAcceptOffer: false,
      active: false,
      needsAmazonValidation: false,
      indicationCode: "",
      hasManualCaptcha: false,
      successful: false,
      message: "",
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeAmazonRefreshToken(e) {
    this.setState({
      amazonRefreshToken: e.target.value,
    });
  }

  onChangeAmazonId(e) {
    this.setState({
      amazonId: e.target.value,
    });
  }

  onChangeExpirationDate(e) {
    this.setState({
      expirationDate: e.target.value,
    });
  }

  onChangeRequestInterval(e) {
    this.setState({
      requestInterval: e.target.value,
    });
  }

  onChangePauseInterval(e) {
    this.setState({
      pauseInterval: e.target.value,
    });
  }

  onChangeUseTurboSearch(e) {
    this.setState({
      useTurboSearch: e.target.checked,
    });
  }

  onChangeStopAfterAcceptOffer(e) {
    this.setState({
      stopAfterAcceptOffer: e.target.checked,
    });
  }

  onChangeActive(e) {
    this.setState({
      active: e.target.checked,
    });
  }

  onChangeNeedsAmazonValidation(e) {
    this.setState({
      needsAmazonValidation: e.target.checked,
    });
  }

  onChangeIndicationCode(e) {
    this.setState({
      indicationCode: e.target.value,
    });
  }

  onChangeHasManualCaptcha(e) {
    this.setState({
      hasManualCaptcha: e.target.checked,
    });
  }

  formatedDate(stringDate) {
    const date = new Date(stringDate);
    const isoDate = new Date(date.toISOString());
    const fixedDate = new Date(
      isoDate.getTime() - date.getTimezoneOffset() * 60000
    );
    return fixedDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
  }

  loadUser() {
    UserService.getUser(this.props.match.params.id).then((response) => {
      this.setState({
        username: response.data.username,
        phone: response.data.phone,
        email: response.data.email,
        password: response.data.password,
        requestInterval: response.data.requestInterval,
        pauseInterval: response.data.pauseInterval,
        useTurboSearch: response.data.useTurboSearch,
        amazonRefreshToken: response.data.amazonRefreshToken,
        amazonId: response.data.amazonId,
        expirationDate: this.formatedDate(response.data.expirationDate),
        stopAfterAcceptOffer: response.data.stopAfterAcceptOffer,
        active: response.data.active,
        needsAmazonValidation: response.data.needsAmazonValidation,
        indicationCode: response.data.indicationCode,
        hasManualCaptcha: response.data.hasManualCaptcha,
      });
    });
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });

    this.loadUser();
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      UserService.update(
        this.props.match.params.id,
        this.state.username,
        this.state.phone,
        this.state.email,
        this.state.password,
        this.state.requestInterval,
        this.state.pauseInterval,
        this.state.useTurboSearch,
        this.state.amazonRefreshToken,
        this.state.amazonId,
        this.state.expirationDate,
        this.state.stopAfterAcceptOffer,
        this.state.active,
        this.state.needsAmazonValidation,
        this.state.indicationCode,
        this.state.hasManualCaptcha
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {
    return (
      <div className="container">
        {this.state.currentUser != null ? (
          <div className="">
            <div className="row d-flex justify-content-center">
              <div>
                <h4 className="text-header">
                  EDIT CLIENT - ID: {this.props.match.params.id}
                </h4>
              </div>
              <Form
                onSubmit={this.handleUpdate}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <hr />
                    <div className="form-group">
                      <label htmlFor="username">
                        <User size={22} /> Username
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="phone">
                        <Phone size={20} /> Phone Number
                      </label>
                      <Input
                        type="phone"
                        className="form-control mt-2 ipt"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        validations={[required, phone]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="email">
                        <Mail size={20} /> Email
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        validations={[required, email]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="password">
                        <Lock size={20} /> New Password
                      </label>
                      <Input
                        type="password"
                        className="form-control mt-2 ipt"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[vpassword]}
                      />
                    </div>

                    <div className="d-flex mt-4">
                      <div className="div-cb">
                        <input
                          className="cb"
                          type="checkbox"
                          name="useTurboSearch"
                          checked={this.state.useTurboSearch}
                          onClick={this.onChangeUseTurboSearch}
                          style={{ width: 20, height: 20 }}
                        />
                        <label htmlFor="useTurboSearch">Turbo Mode</label>
                      </div>
                    </div>
                    <br />

                    <div className="card">
                      <h5>Manual Mode</h5>

                      <div className="form-group mt-3">
                        <label className="text-basic" htmlFor="requestInterval">
                          <Clock size={20} /> Request Interval (seconds)
                        </label>
                        <Input
                          type="number"
                          className="form-control mt-2 ipt"
                          name="requestInterval"
                          value={this.state.requestInterval}
                          onChange={this.onChangeRequestInterval}
                          validations={[required]}
                          disabled={this.state.useTurboSearch}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="text-basic" htmlFor="pauseInterval">
                          <Clock size={20} /> Auto Resume Sleep Time (minutes)
                        </label>
                        <Input
                          type="number"
                          className="form-control mt-2 ipt"
                          name="pauseInterval"
                          value={this.state.pauseInterval}
                          onChange={this.onChangePauseInterval}
                          validations={[required]}
                          disabled={this.state.useTurboSearch}
                        />
                      </div>
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="amazonRefreshToken">
                        <Key size={20} /> Amazon Refresh Token
                      </label>
                      <Input
                        type="textarea"
                        className="form-control mt-2 ipt"
                        name="amazonRefreshToken"
                        value={this.state.amazonRefreshToken}
                        onChange={this.onChangeAmazonRefreshToken}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="amazonId">
                        <Key size={20} /> Amazon User Id
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="amazonId"
                        value={this.state.amazonId}
                        onChange={this.onChangeAmazonId}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="expirationDate">
                        <Key size={20} /> Merbership Expiration
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="expirationDate"
                        value={this.state.expirationDate}
                        onChange={this.onChangeExpirationDate}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="indicationCode">
                        <Key size={20} /> Referral Code
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="indicationCode"
                        value={this.state.indicationCode}
                        onChange={this.onChangeIndicationCode}
                      />
                    </div>

                    <div className="d-flex">
                      <div className="div-cb">
                        <input
                          className="cb"
                          type="checkbox"
                          name="stopAfterAcceptOffer"
                          checked={this.state.stopAfterAcceptOffer}
                          onClick={this.onChangeStopAfterAcceptOffer}
                          style={{ width: 20, height: 20 }}
                        />
                        <label htmlFor="stopAfterAcceptOffer">
                          Stop After Accept Offer
                        </label>
                      </div>

                      <div className="mt-4 div-cb m-4">
                        <input
                          className="cb"
                          type="checkbox"
                          name="active"
                          checked={this.state.active}
                          onClick={this.onChangeActive}
                          style={{ width: 20, height: 20 }}
                        />
                        <label htmlFor="active">Active</label>
                      </div>

                      <div className="mt-4 div-cb m-4">
                        <input
                          className="cb"
                          type="checkbox"
                          name="needsAmazonValidation"
                          checked={this.state.needsAmazonValidation}
                          onClick={this.onChangeNeedsAmazonValidation}
                          style={{ width: 20, height: 20 }}
                        />
                        <label htmlFor="needsAmazonValidation">
                          Need amazon challenge validation
                        </label>
                      </div>

                      <div className="mt-4 div-cb m-4">
                        <input
                          className="cb"
                          type="checkbox"
                          name="hasManualCaptcha"
                          checked={this.state.hasManualCaptcha}
                          onClick={this.onChangeHasManualCaptcha}
                          style={{ width: 20, height: 20 }}
                        />
                        <label htmlFor="hasManualCaptcha">
                          Enable Manual Captcha
                        </label>
                      </div>
                    </div>

                    <div className="form-group mt-4">
                      <button className="form-control btn btn-pages btn-block">
                        <Upload size={17} />
                        <strong> UPDATE</strong>
                      </button>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group mt-3">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
