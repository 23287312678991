import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail, isAlphanumeric } from "validator";
import { User, Lock, Mail, Phone, Upload, Unlock, Clock } from "react-feather";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const phone = (value) => {
  if (value.length !== 12 || value.substring(0, 2) !== "+1") {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        This is not a valid phone. Put +1 before your number. Example
        +11231231234
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The username must be between 3 and 20 characters.
      </div>
    );
  }
  if (!isAlphanumeric(value)) {
    return (
      <div className="alert alert-danger mt-1" role="alert">
        The username must contain only letters and numbers.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value !== "" && (value.length < 6 || value.length > 40)) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeRequestInterval = this.onChangeRequestInterval.bind(this);
    this.onChangePauseInterval = this.onChangePauseInterval.bind(this);
    this.onChangeStopAfterAcceptOffer =
      this.onChangeStopAfterAcceptOffer.bind(this);
    this.onChangeUseTurboSearch = this.onChangeUseTurboSearch.bind(this);
    this.goToSync = this.goToSync.bind(this);

    this.state = {
      currentUser: null,
      username: "",
      phone: "",
      email: "",
      password: "",
      requestInterval: "",
      pauseInterval: "",
      stopAfterAcceptOffer: false,
      active: false,
      useTurboSearch: false,
      successful: false,
      message: "",
      isLoading: true
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  onChangeRequestInterval(e) {
    this.setState({
      requestInterval: e.target.value,
    });
  }

  onChangePauseInterval(e) {
    this.setState({
      pauseInterval: e.target.value,
    });
  }

  onChangeStopAfterAcceptOffer(e) {
    this.setState({
      stopAfterAcceptOffer: e.target.checked,
    });
  }

  onChangeUseTurboSearch(e) {
    this.setState({
      useTurboSearch: e.target.checked,
    });
  }

  loadUser() {
    UserService.getSelf().then((response) => {
      this.setState({
        username: response.data.username,
        phone: response.data.phone,
        email: response.data.email,
        requestInterval: response.data.requestInterval,
        pauseInterval: response.data.pauseInterval,
        stopAfterAcceptOffer: response.data.stopAfterAcceptOffer,
        active: response.data.active,
        useTurboSearch: response.data.useTurboSearch,
        needSync: response.data.needSync,
      });
    });
  }

  goToSync() {
    window.location = "/sync";
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    this.setState({ currentUser: currentUser });
    if (!currentUser) return this.setState({ redirect: "/" });

    this.loadUser();

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500)
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      UserService.updateSelf(
        this.state.username,
        this.state.phone,
        this.state.email,
        this.state.password,
        this.state.requestInterval,
        this.state.pauseInterval,
        this.state.useTurboSearch,
        this.state.stopAfterAcceptOffer
      ).then(
        (response) => {
          this.setState({
            message: response.data.message,
            successful: true,
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            successful: false,
            message: resMessage,
          });
        }
      );
    }
  }

  render() {

    const Loader = () => {
      return (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      );
    };

    return (
      <div className="container">
        {this.state.isLoading && <Loader />}
        {this.state.currentUser != null ? (
          <div className="">
            <div className="row d-flex justify-content-center">
              <div className="d-flex justify-content-center">
                <h4 className="text-header">PROFILE</h4>
              </div>
              <Form
                onSubmit={this.handleUpdate}
                ref={(c) => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="form-group">
                      <label htmlFor="username">
                        <User size={22} /> Username
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="username"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        validations={[required, vusername]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="phone">
                        <Phone size={20} /> Phone Number
                      </label>
                      <Input
                        type="phone"
                        className="form-control mt-2 ipt"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.onChangePhone}
                        validations={[required, phone]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="email">
                        <Mail size={20} /> Email
                      </label>
                      <Input
                        type="text"
                        className="form-control mt-2 ipt"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChangeEmail}
                        validations={[required, email]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label htmlFor="password">
                        <Lock size={20} /> New Password
                      </label>
                      <Input
                        type="password"
                        className="form-control mt-2 ipt"
                        name="password"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        validations={[vpassword]}
                      />
                    </div>

                    <div
                      className="card mt-4"
                      style={{
                        display: this.state.useTurboSearch ? "none" : "block",
                      }}
                    >
                      <h5>Manual Mode</h5>

                      <div className="form-group mt-3">
                        <label className="text-basic" htmlFor="requestInterval">
                          <Clock size={20} /> Request Interval (seconds)
                        </label>
                        <Input
                          type="number"
                          className="form-control mt-2 ipt"
                          name="requestInterval"
                          value={this.state.requestInterval}
                          onChange={this.onChangeRequestInterval}
                          validations={[required]}
                          disabled={this.state.useTurboSearch}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="text-basic" htmlFor="pauseInterval">
                          <Clock size={20} /> Auto Resume Sleep Time (minutes)
                        </label>
                        <Input
                          type="number"
                          className="form-control mt-2 ipt"
                          name="pauseInterval"
                          value={this.state.pauseInterval}
                          onChange={this.onChangePauseInterval}
                          validations={[required]}
                          disabled={this.state.useTurboSearch}
                        />
                      </div>
                    </div>

                    {/* <div className="card mt-3 mb-1">
                      <div className="d-flex justify-content-between">
                        <div className="div-cb">
                          <input
                            className="cb"
                            type="checkbox"
                            name="useTurboSearch"
                            checked={this.state.useTurboSearch}
                            onClick={this.onChangeUseTurboSearch}
                            style={{ width: 20, height: 20 }}
                          />
                          <label
                            htmlFor="useTurboSearch"
                            className="d-flex mt-1"
                          >
                            <h4 className="text-header">Turbo Mode</h4>
                          </label>
                        </div>
                        <div
                          className="btn text-link mt-1"
                          onClick={this.openModal}
                        >
                          <Info size={25} />
                        </div>
                      </div>
                    </div> */}

                    <div className="mt-4 div-cb" style={{ marginLeft: 20 }}>
                      <input
                        className="cb"
                        type="checkbox"
                        name="stopAfterAcceptOffer"
                        checked={this.state.stopAfterAcceptOffer}
                        onClick={this.onChangeStopAfterAcceptOffer}
                        style={{ width: 20, height: 20 }}
                      />
                      <label htmlFor="stopAfterAcceptOffer">
                        Stop After Accept Offer
                      </label>
                    </div>

                    <div className="form-group mt-4">
                      <button className="form-control btn btn-pages btn-block">
                        <Upload size={17} />
                        <strong> UPDATE</strong>
                      </button>
                      <div className="alert">
                        * Notice: If offer searching is running, updated
                        configurations will only take effect after restarting
                        offer searching.
                      </div>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group mt-3">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={(c) => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
          </div>
        ) : null}
        <div className="btn-footer">
          {this.state.needSync ? (
            <div className="form-group d-flex justify-content-center">
              <button
                className="form-control btn-home btn btn-dark btn-block"
                onClick={this.goToSync}
                style={{ fontWeight: "bold", width: 860 }}
              >
                <Unlock size={17} /> LOGIN AMAZON ACCOUNT
              </button>
            </div>
          ) : (
            <div className="form-group d-flex justify-content-center">
              <button
                disabled
                className="form-control btn-home btn btn-dark btn-block"
                onClick={this.goToSync}
                style={{ fontWeight: "bold", width: 860 }}
              >
                <Lock size={17} /> AMAZON ACCOUNT LINKED ✔️
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
